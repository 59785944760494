<template>
  <div>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />  

    <div class="content">
      <validation-observer ref="VFormDPCriteria">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <div class="row align-items-center">
                <div class="col-md-auto">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <h5 class="card-title font-weight-semibold">{{ $parent.objName }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-sm  table-bordered">
                    <tbody>
                      <tr>
                        <th colspan="2" class="bg-primary">KRITERIA FAKTOR RESIKO: </th>
                      </tr>
                      <!--
                      {{mrCriteria}}
                      -->
                      <tr v-for="(v, k) in mrCriteria" :key="k">
                        <td width="400">{{ v.label }} </td>
                        <td>
                          <div>
                            <b-form-group>
                              <b-form-radio-group
                                :id="`radio-group-${k}`"
                                v-model="v.value"
                                :name="`criteria${k}`"
                              >
                                <b-form-radio value="YA">Ya</b-form-radio>
                                <b-form-radio value="TIDAK">Tidak</b-form-radio>
                              </b-form-radio-group>
                            </b-form-group>
                          </div>
                        </td>
                      </tr>                  
                    </tbody>
                    <tbody v-if="isFitCriteria">
                      <tr>
                        <th colspan="2" class="bg-primary">PERENCANAAN PULANG </th>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div>
                            <b-form-group>
                              <b-form-checkbox-group
                                v-model="formData.discharge_planning"
                                name="`dischargePlanning`"
                              >
                                <b-form-checkbox 
                                  v-for="(v, k) in mrDischargePlanning" :key="k" 
                                  :value="v.label"
                                  :id="`dischargePlanning${k}`"
                                  class="mb-2"
                                >
                                  {{ v.label }}
                                </b-form-checkbox>
                              </b-form-checkbox-group>

                              <VValidate 
                                name="Perencanaan Pulang"
                                :value="formData.discharge_planning.length > 0 ? true : null"
                                :rules="mrValidation.discharge_planning"
                              />
                            </b-form-group>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Gen from '@/libs/Gen'


export default {
  components: {
    PatientInfo
  },

  data(){
    return {
      loading: {
        patientInfo: false
      },

      patientData: {},

      mrCriteria: [],
      mrDischargePlanning: [],
      mrValidation: {},

      formData: {
        discharge_planning: [],
        is_discharge: false
      },
    }
  },

  mounted(){
    this.getPatientInfo()
    this.getDataForm()
  },

  methods: {
    getPatientInfo(){
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/"+'RanapPasienPerawatan',
        { 
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getDataForm(){
      Gen.apiRest(
        "/do/"+'RanapPasienPerawatan',
        { 
          data: {
            type: "get-form-discharge-planning",
            id_registrasi: this.$route.params.pageSlug
          } 
        }, 
        "POST"
      ).then(resp => {
        const { mrCriteria, mrDischargePlanning, mrValidation } = resp.data
        

        this.$set(this, 'mrCriteria', mrCriteria)
        this.$set(this, 'mrDischargePlanning', mrDischargePlanning)
        this.$set(this, 'mrValidation', mrValidation)
        this.formData.discharge_planning = resp.data.discharge_planning
      })
    },

    doSubmit(){
      this.$refs['VFormDPCriteria'].validate().then(success => {
        if(!success) return false
        this.$swal.fire({
          title: 'Loading...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          "/do/"+'RanapPasienPerawatan',
          { 
            data: {
              ...this.formData,
              id_registrasi: this.$route.params.pageSlug,
              criteria: this.mrCriteria,
              type: "add-criteria-discharge-planning"
            } 
          }, 
          "POST"
        ).then(resp => {
          this.$swal.close()
          this.$swal({
            title: resp.data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then(result => {
            if (result.value) {
              this.$router.push({ name: "RanapPasienPerawatan" })
            }
          })
        }).catch(()=>{
          this.$swal.close()

          return this.$swal({
            icon: 'error',
            text: 'Terjadi Kesalahan Yang Tidak Diketahui'
          })
        })
      })
    }
  },

  watch: {
    $route() {
      this.getPatientInfo()
      this.getDataForm()
    },
  },

  computed: {
    isFitCriteria(){
      const fitCriteria = this.mrCriteria.map(mc => mc.value).find(mc => mc == 'YA')
      
      if(!fitCriteria) this.$set(this.formData, 'discharge_planning', [])

      this.$set(this.formData, 'is_discharge', fitCriteria ? true : false)
      return true
    }
  }
}
</script>